<template>
  <b-card>
    <b-row>
      <!-- User Info: Left col -->
      <b-col
        cols="21"
        xl="6"
        class="d-flex justify-content-between flex-column"
      >
        <!-- User Avatar & Action Buttons -->
        <div class="d-flex justify-content-start">
          <b-avatar
            :src="userData.avatar"
            :text="avatarText(userData.first_name+' '+userData.last_name)"
            :variant="`light-${resolveUserRoleVariant('customer')}`"
            size="104px"
            rounded
          />
          <div class="d-flex flex-column ml-1">
            <div class="mb-1">
              <h4 class="mb-0">
                {{ userData.first_name+' '+userData.middle_name+' '+userData.last_name }}
              </h4>
              <span class="card-text">{{ userData.email }}</span>
            </div>
            <div class="d-flex flex-wrap">
              <b-button
                :to="{ name: 'apps-users-edit', params: { id: userData.id } }"
                variant="primary"
              >
                Edit
              </b-button>
              <b-button
                variant="outline-danger"
                class="ml-1"
                @click="deleteAccount(userData.id)"
              >
                Delete
              </b-button>
            </div>
          </div>
        </div>

        <!-- User Stats -->
        <div class="d-flex align-items-center mt-2">
          <div class="d-flex align-items-center mr-2">
            <b-avatar
              variant="light-primary"
              rounded
            >
              <!-- <feather-icon
                icon="DollarSignIcon"
                size="18"
              /> -->
              ₦
            </b-avatar>
            <div class="ml-1">
              <h5 class="mb-0">
                {{ userData.ai_credit }}
              </h5>
              <small>AI Credit</small>
            </div>
          </div> 

          <div class="d-flex align-items-center">
            <b-avatar
              variant="light-success"
              rounded
            >
              <feather-icon
                icon="TrendingUpIcon"
                size="18"
              />
            </b-avatar>
            <div class="ml-1">
              <h5 class="mb-0">
                {{ userData.kyc_tier}}
              </h5>
              <small>Account Tier</small>
            </div>
          </div>
        </div>
      </b-col>

      <!-- Right Col: Table -->
      <b-col
        cols="12"
        xl="6"
      >
        <table class="mt-2 mt-xl-0 w-100">
          <tr>
            <th class="pb-50">
              <feather-icon
                icon="UserIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">User Type</span>
            </th>
            <td class="pb-50">
              Customer
            </td>
          </tr>
          <tr>
            <th class="pb-50">
              <feather-icon
                icon="CheckIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">Status</span>
            </th>
            <td :class="'pb-50 text-capitalize '">
              {{ userData.profile_status != ' ' ? userData.profile_status : 'Pending' }}
            </td>
          </tr>
          <tr>
            <th class="pb-50">
              <feather-icon
                icon="StarIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">Referral Code</span>
            </th>
            <td class="pb-50 text-capitalize">
              {{ userData.referer_code }}
            </td>
          </tr>
          <tr>
            <th class="pb-50">
              <feather-icon
                icon="FlagIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">State/City</span>
            </th>
            <td class="pb-50">
              {{ userData.state+'/'+userData.city }}
            </td>
          </tr>
          <tr>
            <th>
              <feather-icon
                icon="PhoneIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">Contact</span>
            </th>
            <td>
              {{ userData.phone_number }}
            </td>
          </tr>
          <tr>
            <th>
              <feather-icon
                icon="CheckIcon"
                class="mr-75"
                variant="success"
              />
              <span class="font-weight-bold">Joined</span>
            </th>
            <td>
              {{ userData.created_at_formatted }}
            </td>
          </tr>
          <tr>
            <th>
              <feather-icon
                icon="CheckIcon"
                class="mr-75"
                variant="success"
              />
              <span class="font-weight-bold">Username</span>
            </th>
            <td>
              {{ userData.username }}
            </td>
          </tr>
        </table>
      </b-col>
    </b-row>
    <div class="d-flex justify-content-between flex-wrap mt-1">
      <b-button-group class="mb-2">
        <b-button type="button" class="btn-sm" variant="primary" @click="toggleWallets">
          <feather-icon
            icon="MoneyIcon"
          />
          Wallets
        </b-button>
        <b-button type="button" class="btn-sm" variant="secondary" @click="toggleCrypto">
          <feather-icon
            icon="CryptoIcon"
          />
          Crypto
        </b-button>
      </b-button-group>

      <b-button-group class="mb-2">
        <b-button class="btn-sm" variant="secondary" @click="toggleSendMessage(userData.id)">
          <feather-icon icon="MessageCircleIcon" />
        </b-button>
        <b-button class="btn-sm" variant="primary" @click="manageProfile('suspend', userData.id)" v-show="userData.profile_status === 'APPROVED'">Suspend</b-button>
        <b-button class="btn-sm" variant="danger" @click="manageProfile('close', userData.id)" v-show="userData.profile_status === 'APPROVED'">Close</b-button>
        <b-button class="btn-sm" variant="success" @click="manageProfile('activate', userData.id)" v-show="userData.profile_status !== 'APPROVED'">Activate</b-button>
        <button type="button" class="btn  btn-sm text-primary  btn-light" @click="toggleMore">
          See more 
          <feather-icon
            icon="ChevronDownIcon"
            class="mr-75"
          />
        </button>
      </b-button-group>
    </div>
    <hr>
    <b-row class="mt-3m" v-show="view_more">
      <b-col
        cols="12"
        xl="6"
      >
        <table class="mt-2 mt-xl-0 w-100">
          <tr>
            <th class="pb-50">
              <span class="font-weight-bold">BVN</span>
            </th>
            <td class="pb-50">
              {{ userData.bvn }}
            </td>
          </tr>
          <tr>
            <th class="pb-50">
              <span class="font-weight-bold">Street</span>
            </th>
            <td :class="'pb-50 text-capitalize '">
              {{ userData.street_name }}
            </td>
          </tr>
          <tr>
            <th class="pb-50">
              <span class="font-weight-bold">City</span>
            </th>
            <td :class="'pb-50 text-capitalize '">
              {{ userData.city }}
            </td>
          </tr>
          <tr>
            <th class="pb-50">
              <span class="font-weight-bold">State</span>
            </th>
            <td class="pb-50 text-capitalize">
              {{ userData.state }}
            </td>
          </tr>
          <tr>
            <th class="pb-50">
              <span class="font-weight-bold">Place of Birth</span>
            </th>
            <td class="pb-50">
              {{userData.place_of_birth}}
            </td>
          </tr>
          <tr>
            <th class="pb-50">
              <span class="font-weight-bold">Country</span>
            </th>
            <td class="pb-50">
              Nigeria
            </td>
          </tr>
        </table>
      </b-col>
      <b-col
        cols="12"
        xl="6"
      >
        <table class="mt-2 mt-xl-0 w-100">
          <tr>
            <th class="pb-50">
              <span class="font-weight-bold">Gender</span>
            </th>
            <td class="pb-50">
              {{ userData.gender }}
            </td>
          </tr>
          <tr>
            <th class="pb-50">
              <span class="font-weight-bold">Date of Birth</span>
            </th>
            <td :class="'pb-50 text-capitalize '">
              {{ userData.dob }}
            </td>
          </tr>
        </table>
      </b-col>
      <!-- <b-col cols="12" xl="12" align-self="end" class="text-right">
        <b-button-group>
          <b-button variant="primary" @click="manageProfile('suspend')" v-show="userData.profile_status === 'APPROVED'">Suspend</b-button>
          <b-button variant="danger" @click="manageProfile('close')" v-show="userData.profile_status === 'APPROVED'">Close</b-button>
          <b-button variant="success" @click="manageProfile('activate')" v-show="userData.profile_status !== 'APPROVED'">Activate</b-button>
        </b-button-group>
      </b-col> -->
    </b-row>
    <!-- Wallets -->
    <b-row class="mt-3m table-responsive" v-show="showWallets">
      <b-col
        sm="12"
        xl="12"
        md="12"
      >
        <h5>Multi-Currency Wallets</h5>
        <table class="mt-2 mt-xl-0 w-100 table-striped table">
          <thead>
            <tr>
              <th class="pb-50">
                <span class="font-weight-bold">Currency</span>
              </th>
              <th class="pb-50">
                <span class="font-weight-bold">Balance</span>
              </th>
              <th class="pb-50">
                <span class="font-weight-bold"></span>
              </th>
            </tr>
          </thead>
          <tr v-for="wallet in wallets" :key="wallet.id">
            <td class="pb-50">
              {{wallet.currency}}
            </td>
            <td class="pb-50">
              {{ wallet.balance }}
            </td>
            <td>
              <b-button-group>
                <b-button
                  class="mb-1 btn-sm"
                  variant="primary"
                  :disabled="wallet.currency === 'USD' || wallet.currency === 'EUR'"
                  v-b-modal.modal-center
                  @click="selectWallet(wallet.id)"
                >
                  <feather-icon icon="PlusIcon" />
                </b-button>
                <b-button
                  class="mb-1 btn-sm"
                  variant="secondary"
                  @click="manageWallet('suspend', wallet.id, 'wallet')"
                >
                  <feather-icon icon="LockIcon" />
                </b-button>
              </b-button-group>
            </td>
          </tr>
        </table>
      </b-col>
      <!-- <b-col cols="12" xl="12" align-self="end" class="text-right">
        <b-button-group>
          <b-button variant="primary" @click="manageProfile('suspend')" v-show="userData.profile_status === 'APPROVED'">Suspend</b-button>
          <b-button variant="danger" @click="manageProfile('close')" v-show="userData.profile_status === 'APPROVED'">Close</b-button>
          <b-button variant="success" @click="manageProfile('activate')" v-show="userData.profile_status !== 'APPROVED'">Activate</b-button>
        </b-button-group>
      </b-col> -->
    </b-row>
    <!-- crypto -->
    <b-row class="mt-3m table-responsive" v-show="showCrypto">
      <b-col
        sm="12"
        xl="12"
        md="12"
      >
        <h5>Crypto Currency Wallets</h5>
        <table class="mt-2 mt-xl-0 w-100 table-striped table">
          <thead>
            <tr>
              <th class="pb-50">
                <span class="font-weight-bold">Name</span>
              </th>
              <th class="pb-50">
                <span class="font-weight-bold">Currency</span>
              </th>
              <!-- <th class="pb-50">
                <span class="font-weight-bold">Naira Value</span>
              </th> -->
              <th class="pb-50">
                <span class="font-weight-bold">Balance</span>
              </th>
              <th class="pb-50">
                <span class="font-weight-bold"></span>
              </th>
            </tr>
          </thead>
          <tr v-for="wallett in cryptoWallets" :key="wallett.id">
            <td class="pb-50">
              {{wallett.name}}
            </td>
            <td class="pb-50">
              {{wallett.currency.toUpperCase()}}
            </td>
            <!-- <td class="pb-50">
              {{ wallett.naira_value }}
            </td> -->
            <td class="pb-50">
              {{ wallett.balance }}
            </td>
            <!-- <td>
              <b-button-group>
                <b-button
                  class="mb-1 btn-sm"
                  variant="primary"
                  :disabled="wallett.currency === 'USD' || wallett.currency === 'EUR'"
                  v-b-modal.modal-center
                  @click="selectWallet(wallett.id)"
                >
                  <feather-icon icon="PlusIcon" />
                </b-button>
                <b-button
                  class="mb-1 btn-sm"
                  variant="secondary"
                  @click="manageWallet('suspend', wallett.id, 'wallet')"
                >
                  <feather-icon icon="LockIcon" />
                </b-button>
              </b-button-group>
            </td> -->
          </tr>
        </table>
      </b-col>
      <!-- <b-col cols="12" xl="12" align-self="end" class="text-right">
        <b-button-group>
          <b-button variant="primary" @click="manageProfile('suspend')" v-show="userData.profile_status === 'APPROVED'">Suspend</b-button>
          <b-button variant="danger" @click="manageProfile('close')" v-show="userData.profile_status === 'APPROVED'">Close</b-button>
          <b-button variant="success" @click="manageProfile('activate')" v-show="userData.profile_status !== 'APPROVED'">Activate</b-button>
        </b-button-group>
      </b-col> -->
    </b-row>
    <b-row class="mt-3m" v-show="showMessageForm">
      <b-col
        cols="12"
        xl="6"
      >
        <h5>Send Push notification messages to customer</h5>
      <form action="#" method="POST"> 
        <b-card>
          <b-row>
            <b-col md="12">
              <b-form-group
                label="Title"
                label-for="mc-title"
              >
                <b-form-input
                  id="mc-title"
                  v-model="payload.title"
                  placeholder="Title"
                />
              </b-form-group>
            </b-col>
            <b-col md="12">
              <b-form-group
                label="Message"
                label-for="mc-message"
              >
                <b-form-textarea
                  id="mc-message"
                  v-model="payload.description"
                  placeholder="Message"
                ></b-form-textarea>
              </b-form-group>
            </b-col>
            <b-col>
              <b-button
                type="button"
                variant="primary"
                class="mr-1"
                @click="sendMessage"
              >
                Send
                <b-spinner
                  v-show="loading"
                  variant="light"
                  small
                />
              </b-button>
            </b-col>
          </b-row>
        </b-card> 
      </form>
      </b-col>
    </b-row>

    <b-modal
      id="modal-center"
      centered
      title="Fund Wallet"
      hide-footer
    >
      <b-form>
        <b-form-group
          label="Amount"
          label-for="amount"
        >
          <b-form-input
            v-model="formdata.amount"
            name="amount"
            placeholder="amount"
            type="number"
          />
        </b-form-group>

        <b-form-group
          label="Narration"
          label-for="narration"
        >
          <b-form-input
            v-model="formdata.narration"
            name="narration"
            placeholder="narration"
            type="text"
          />
        </b-form-group>

        <b-form-group>
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            class="mt-3"
            variant="primary"
            block
            @click="fundWallet"
          >
            Submit
            <b-spinner
              v-show="loading"
              variant="light"
              small
            />
          </b-button>          
        </b-form-group>
      </b-form>
    </b-modal> 
  </b-card>
</template>

<script>
import {
  BCard, BButton, BAvatar, BRow, BCol, BButtonGroup, BFormGroup, BFormInput, BSpinner, BFormTextarea, VBModal,
  BForm
} from 'bootstrap-vue'
import { avatarText } from '@core/utils/filter'
import useUsersList from '../users-list/useUsersList'
import Swal from 'sweetalert2'
import FeatherIcon from '@/@core/components/feather-icon/FeatherIcon.vue'
import Feather from '@/views/ui/feather/Feather.vue'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BCard, BButton, BRow, BCol, BAvatar, BButtonGroup, BSpinner,
    FeatherIcon,
    BFormGroup, 
    BFormInput,
    BFormTextarea,
    Feather,
    BForm,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  props: {
    userData: {
      type: Object,
      required: true,
    },
    wallets: {
      type: Array,
      required: true,
    },
    cryptoWallets: {
      type: Array,
      required: true,
    }
  },
  setup() {
    const { resolveUserRoleVariant } = useUsersList()
    return {
      avatarText,
      resolveUserRoleVariant,
    }
  },
  data() {
    return {
      view_more: false,
      showMessageForm: false,
      loading: false,
      showWallets: false,
      showCrypto: false,
      payload: {
        title: '',
        description: '',
        priority: 'high',
        recipient: ''
      },
      customer_id: '',
      formdata: {},
    }
  },
  methods: {
    toggleMore() {
      this.view_more = !this.view_more
      this.showMessageForm = false
      this.showWallets = false
      this.showCrypto = false
    },
    toggleWallets() {
      this.showWallets = !this.showWallets
      this.showCrypto =  false
      this.view_more = false
      this.showMessageForm = false
    },
    toggleCrypto() {
      this.showCrypto =  !this.showCrypto
      this.showWallets = false
      this.view_more = false
      this.showMessageForm = false
    },
    toggleSendMessage(customer_id) {
      this.showMessageForm = !this.showMessageForm
      this.view_more = false
      this.payload.recipient = customer_id
      this.showWallets = false
      this.showCrypto = false
    },
    deleteAccount(id) {
      Swal.fire({
        icon: 'warning',
        text: 'Are you sure you want to delete this customer?',
        showCancelButton: true,
        confirmButtonColor: '#34c38f',
        cancelButtonColor: '#ff0000',
        confirmButtonText: 'Yes Delete!',
        cancelButtonText: 'No, Cancel',
        allowOutsideClick: false,
      })
      .then((result) => {
        if (result.isConfirmed === true) {
          this.$http.delete(`${this.$url}/customers/${id}`)
          .then((response) => {
            if(response.status) {
              Swal.fire({
                icon: 'success',
                text: 'Customer deleted successfully'
              })
              .then(() => {
                window.location.replace('apps/users/list')
              })
            }
          }) 
          .finally(() => {
            this.loading = false
          })
        }
      })
    },
    manageProfile(option, user_id) {
      Swal.fire({
        icon: 'warning',
        text: `Are you sure you want to ${option} this customer?`,
        showCancelButton: true,
        confirmButtonColor: '#34c38f',
        cancelButtonColor: '#ff0000',
        confirmButtonText: 'Yes Proceed!',
        cancelButtonText: 'No, Cancel',
        allowOutsideClick: false,
      })
      .then((result) => {
        if (result.isConfirmed === true) {
          this.$http.post(`${this.$url}/customers/manage-profile`, {customer_id: user_id, option: option})
          .then((response) => {
            if(response.data.status) {
              Swal.fire({
                icon: 'success',
                text: response.data.message
              })
              // .then(() => {
              //   window.location.replace('apps/users/list')
              // })
            }
          }) 
          .finally(() => {
            this.loading = false
          })
        }
      })
    },
    manageWallet(option, wallet_id, type) {
      Swal.fire({
        icon: 'warning',
        text: `Are you sure you want to ${option} this wallet?`,
        showCancelButton: true,
        confirmButtonColor: '#34c38f',
        cancelButtonColor: '#ff0000',
        confirmButtonText: 'Yes Proceed!',
        cancelButtonText: 'No, Cancel',
        allowOutsideClick: false,
      })
      .then((result) => {
        if (result.isConfirmed === true) {
          if(option === 'suspend') {
            this.$http.post(`${this.$url}/wallets/${wallet_id}/manage`, {wallet_id: wallet_id, action: option})
            .then((response) => {
              if(response.data.status) {
                Swal.fire({
                  icon: 'success',
                  text: response.data.message
                })
              }
            }) 
            .catch((error) => {
              Swal.fire({
                icon: 'error',
                text: error.response.data.message
              })
            })
            .finally(() => {
              this.loading = false
            })
          }
        }
      })
    },
    fundWallet() {
      this.loading = true
      this.$http.post(`${this.$url}/wallets/funding`, {
        wallet_id: this.formdata.wallet_id, 
        amount: this.formdata.amount,
        narration: this.formdata.narration
      })
      .then((response) => {
        if(response.data.status) {
          Swal.fire({
            icon: 'success',
            text: response.data.message
          })
        }
      }) 
      .catch((error) => {
        Swal.fire({
          icon: 'error',
          text: error.response.data.message
        })
      })
      .finally(() => {
        this.loading = false
      })
    },
    fundCryptoWallet(wallet_id) {
      this.$http.post(`${this.$url}/wallets/crypto/fund-wallet`, {wallet_id: wallet_id, amount: this.formdata.amount})
      .then((response) => {
        if(response.data.status) {
          Swal.fire({
            icon: 'success',
            text: response.data.message
          })
        }
      }) 
      .catch((error) => {
        Swal.fire({
          icon: 'error',
          text: error.response.data.message
        })
      })
      .finally(() => {
        this.loading = false
      })
    },
    sendMessage() {

      if(this.payload.title === '' || this.payload.description === '' || this.payload.recipient === '') { return }
      this.loading = true
      this.$http.post(`${this.$url}/support/create`, this.payload)
      .then((response) => {
        if(response.data.success) {
          Swal.fire({
            icon: 'success',
            text: response.data.message
          })
          .then(() => {
            this.payload.title = ''
            this.payload.description = ''
          })
          // .then(() => {
          //   window.location.replace('apps/users/list')
          // })
        }
      }) 
      .finally(() => {
        this.loading = false
      })
    },
    selectWallet(wallet_id) {
      this.formdata.wallet_id = wallet_id
    },
  },
}
</script>

<style>

</style>
